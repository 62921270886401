/* new confirm */
.coverbg_new{
   display: flex;
            justify-content: center; /* Horizontally center */
            align-items: center;    /* Vertically center */

   text-align: center;
   position:absolute;top:0px;left:0px;
   /* z-index:10; */
   z-index: 999999;
   display:none;
}
@media (min-width:1024px){
   #confirm_new{
      z-index: 1;
       font-family: "Open Sans",sans-serif;
       font-size: 18px;
       border: 2px solid #254fd4;
        display: none;
        background-color: #F3F5F6;
        color: #000000;
       
        position: fixed;
        width: 30%;
        min-height: 30vh;
        left: 30%;
        top: 20%;
        box-sizing: border-box;
        text-align: center;
        margin-bottom: 10px;
   }
}

@media (max-width:1023px){
   #confirm_new{
      z-index: 1;
      font-family: "Open Sans",sans-serif;
      font-size: 18px;
      border: 2px solid #254fd4;
       display: none;
       background-color: #F3F5F6;
       color: #000000;
      
       position: fixed;
       width: 90%;
       min-height: 30vh;
       
       top: 20%;
       box-sizing: border-box;
       text-align: center;
       margin-bottom: 10px;
      width: 90%;
      /* left: 0%; */
      margin-left: 5%;
   }
   
}
#confirm_new span{
   display: block;
  }
#formAlertdiv{
   display: block;
    
    
   padding: 15px;
  margin-left: 15px;
  margin-right: 15px;
}
#confirm_new .message {
   font-size: 22px;
   font-weight: 400;
   text-align: left;
top:0;
padding-left: 20px;
width:100%;
background-color:#17a2b8;
padding-top:5px;
padding-bottom:5px;
color: #fff;
}

#btnAlertLogin{
   margin-bottom: 20px;
}

/* end of new alert */
.parentdiv{
    position:relative;
 }
 .coverbg{
    position:absolute;top:0px;left:0px;
    /* z-index:10; */
    z-index: 999999;
    display:none;
 }
 #confirm {
    z-index: 1;
    font-family: "Open Sans",sans-serif;
    font-size: 18px;
    border: 2px solid #254fd4;
     display: none;
     background-color: #F3F5F6;
     color: #000000;
    
     position: fixed;
     width: 30%;
     min-height: 30vh;
     left: 30%;
     top: 20%;
     box-sizing: border-box;
     text-align: center;
     margin-bottom: 10px;
  }
  #confirm span{
   display: block;
  }
  #formdiv{
     
     display: block;
    
    
     padding: 15px;
    margin-left: 15px;
    margin-right: 15px;
  }
  #yesnobtn{
     background-color: #FFFFFF;
     display: inline-block;
     border-radius: 12px;
     border: 4px solid #aaa;
     padding: 5px;
     text-align: center;
     width: 60px;
     cursor: pointer;
  }
  #confirm .message {
     font-size: 22px;
     font-weight: 400;
     text-align: left;
 top:0;
 padding-left: 20px;
 width:100%;
 background-color:#17a2b8;
 padding-top:5px;
 padding-bottom:5px;
 color: #fff;
  }
 .icondesign
 {
    margin: 5px;
    justify-content:space-between;
 color:#fff;
 
 float:right;
 }
 .flabel{
    padding-right: 10px;
    padding-left: 15px;  
    font-size: 18px;
    margin-bottom: 20px;
    display: block;
    word-wrap:break-word;
 }
 
 .butn{
    border: 1px solid #17a2b8 ;
    background-color: #dc3545;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    border-radius: 6px;
    padding-left: 50px;
    padding-right: 50px;
    height:32px;
 }