
@media only screen and (min-width: 1023px) {
    .nav-menu-top-item{
        color:white !important;
    }
}

.navbar-nav .nav-item a{
    font-size: 17px;
    font-style: bold;
}
.dropdown-menu {
    left: auto !important;
    right: 0 !important;
}
#nav-close-btn {
    z-index: 9999;
}

#menu_opt .close{
    z-index: 9999;
} 

@media (max-width:1023px){
    .navbar-brand .brand-text{
        display: none;
    }
    .navbar-collapse{
        flex-basis: auto !important;
    }
    #menu_opt .logo-img{
        width: 90px;
        height: 90px;
    }
    #menu_opt{
        /* max-height: 99vh; */
        overflow-y: scroll; 
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); 
    }
    .top-mobile-dropdown{
        margin-top: 50px;
    }
    .top-mobile{
        float: right;
    }
    .ml-auto{
        /* display: none !important; */
        /* display: flex;
        align-items: right; */
    }
    .ml-auto .nav-item .nav-link .white{
        color: #fff;
    }
    .brand-image{
        max-width: 100px;
    }
    .img-circle{
        max-height: 100px;
    }
    .ml-auto .nav-item{
        /* display: flex;
        flex-direction: column; */
        /* max-width: 70%; */
    }

}