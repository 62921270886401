/* Rules for sizing the icon. */
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }
.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

.p-margin
{
	margin-bottom: 3px !important;
	
}



.box-me
{
	position:fixed;
	bottom: 0;
}

.my-shadow
{
	
	box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 75%);
-webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 75%);
-moz-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 75%);
}


.dropdown-item.active, .dropdown-item:active {
    
     background-color:transparent !important;
}
.dropdown-item:focus, .dropdown-item:hover {
   
     background-color:#179ab8 !important;
	 color:#fff !important;
}

.dd-set{
	
	position: absolute !important;
	top:47px !important;
	padding: 8px;
	
	
	
}
.top-bor
{
	width: 100%;
	border-top: 5px solid #173D6B;
}



/* mobile navbar sidebar under lg breakpoint */
@media (max-width: 992px) {
    
    .navbar-collapse.collapsing .navbar-nav {
        display: block;
        position: fixed;
        top: 30px;
        bottom: 0;
        left: -75%;
        transition: all 0.2s ease;
		
    }

    .navbar-collapse.show .navbar-nav {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        flex-direction: column;
        height: auto;
        width: 75%;
        transition: left 0.35s ease;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
		z-index: 999999999;


	
    }
}



/*  for menubar */
h6
{
	font-size: 15px;
	color: #323232;
	text-rendering: optimizeLegibility;

-webkit-font-smoothing: antialiased;
}

.font-small
{
	font-size: 12px !important;
	opacity: .8
	
}
.text-light-gray
{
color:#515151;	
}
.hover:hover
{
	opacity: .6;
	
}
.my-bg-color-1
{
	background-color:#179ab8;
/*background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 3%, rgba(11,82,139,1) 100%);*/
	color:#fff !important;
}
.text-white
{
color:#FFFFFF !important;
}

a{
	/* color:#414141 !important; */
}
.content-wrapper > .content
{
background-color:#f3f3f3 !important;
}

.nav-item .ff
{
	color:#fff !important;
}
.text-green
{
color:#6db784;
}
.text-orange
{
	color: #b81770 !important;
}

.bg-cyan
{
	background-color:#b81770 !important; 
}


body,html,.container
{
overflow-x:hidden !important ;
background-color:#f3f3f3;
	color:#4B4B4B !important;
	text-rendering: optimizeLegibility !important;

-webkit-font-smoothing: antialiased !important;
}

/* width */
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  overflow-x: hidden;
}

/* Track */
::-webkit-scrollbar-track {
   
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background:#1D6FB1; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:#4098DF; 
  
  
}





@media screen and (max-width: 800px) {

.my-ff
{
border:0 !important;
margin-top:20px;
}
	.me-hide{
		display: none;

	}
	.bg-light-my
	{
		background-color: #ffffff !important;
	}
	.dd{
		color:#454545 !important;
	}
	
	.dropdown-item {
   
    padding: .25rem .1rem;
   
}
	
	.dd-set{
	
	position: absolute !important;
	top:30px !important;
	padding: 8px;
		width: 100% !important;
	
		
		
	
	
	
}
	.ffg
	{
		font-size: 30px;
		color: #ffffff;
	}
}
.ggg {
    background-color: transparent !important;
}
.d-set {
    position: absolute;
    top: 10%;
}

table{
	display: table !important;
} 
tr{
	display: table-row !important;
	overflow: scroll;
}
td{
	display:table-cell !important;
}
.card{
	overflow-x: scroll;
}

